* {
  box-sizing: border-box;
}

html {
  scroll-padding-top: 96px;
  background:var(--mainBlue);
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  font-family: "InterRegular";
  scroll-behavior: smooth;
  background:var(--mainBlue);
}

:root {
  --white: #ffffff;
  --mainBlue: #024266;
  --mainBlueDark: #002947;
  --red: #ff6f74;
  --darkRed: #ff636f;
  --primaryGradient: linear-gradient(135deg, #5ee6e5 0%, #00bad8 53.4%, #00b5d4 100%);
  --redMainColor: #ff6f74;
  --primaryColor: #00DDED;
  --mainFontColor: #B1C6D4;
  --secondaryFontColor: #004369;
}

.slick-dots {
  position: relative !important;
  padding: 10px !important;
  background-color: var(--mainBlue);
  bottom: auto !important;
  .slick-active button {
      background-color: var(--primaryColor) !important;
      & ~ span.paginator-label {
          display: block;
      }
  }
}
.slick-dots li button {
  border: none;
  padding: 3px !important;
  border-radius: 50%;
  margin: 6px;
  background-color: var(--mainBlueDark) !important;
  width: 0 !important;
  height: 0 !important;
  &::before {
      content: none !important;
  }
  & ~ span.paginator-label {
      display: none;
      font-size: 11px;
      color: var(--white);
      position: absolute;
      top: 14px;
      left: 50%;
      transform: translateX(-50%);
  }
}
.slick-dotted.slick-slider {
  margin-bottom: 0 !important;
}
